<template>
  <div class="home-class-main">
    <div class="slider-category-title loading"></div>
    <div class="slider-header">
      <div class="seeall">
      </div>
    </div>
    <div :class="['swiper', `ok${id}`]" style="position: relative;">
      <div class="swiper-wrapper">
        <div :class="['swiper-slide', screen.displayType === 'LANDSCAPE' ? 'landscape-img-view' : 'portrait-img-view']"
          :id="`swiper-slide-${index}`" v-for="(item, index) in contentList" :key="index">
          <div class="skeleton loading"></div>
        </div>
      </div>
      <div class="swiper-button-next">
        <div class="next-btn-onhover"></div>
      </div>
      <div class="swiper-button-prev">
        <div class="prev-btn-onhover"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['id', 'screenName', 'screen',],
  data() {
    return {
      contentList: 8,
      displayType: '',
      isMobile: false,
    }
  },

  mounted() {
    // Add mousemove listener to the entire document
    this.isMobile = window.innerWidth <= 900 ? true : false,
    this.displayType = this.screen?.displayType == 'LANDSCAPE' ? 'LANDSCAPE' : 'PORTRAIT';
    let breakpoints = {
      300: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 3 : 2,  // Adjust the number of posters for mobile devices
        spaceBetween: 5  // Adjust the space between posters for mobile devices
      },
      420: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 2.5 : 1.7,  // Adjust the number of posters for mobile devices
        spaceBetween: 5  // Adjust the space between posters for mobile devices
      },
      500: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 3.5 : 2,  // Adjust the number of posters for mobile devices
        spaceBetween: 7  // Adjust the space between posters for mobile devices
      },
      630: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 4 : 2.8,  // Adjust the number of posters for mobile devices
        spaceBetween: 10  // Adjust the space between posters for mobile devices
      },
      768: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 4 : 3,  // Adjust the number of posters for mobile devices
        spaceBetween: 12  // Adjust the space between posters for mobile devices
      },
      // when window width is >= 480px
      1024: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 4.7 : 3.5,
        spaceBetween: 20
      },
      // when window width is >= 640px
      1280: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 6.3 : 4.7,
        spaceBetween: 10
      },
      1500: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 7.5 : 6.1,
        spaceBetween: 10
      },
      1800: {
        slidesPerView: this.displayType == 'PORTRAIT' ? 8.5 : 6.3,
        spaceBetween: 10
      }
    }


    let swiper = new window.Swiper(`.ok${this.id}`, {
      slidesPerView: 2,  // Set the number of slides directly for testing
      cssMode: true,
      lazy: true,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      on: {
        init: function () {
          this.isDisableOnHover = true;
        },
      },
      breakpoints,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });

  }
}
</script>

<style scoped lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

:root {
  --blue: #1e90ff;
  --white: $clr-saina-light-dark-txtf;
  --VERTICAL-CARD-HOVER-SCALE-X: 0.8;
  --VERTICAL-CARD-HOVER-SCALE-Y: 0.8;
}

.swiper {
  width: 100%;
  height: 100%;
}

.landscape-img-view {
  aspect-ratio: 16 / 9;
}

.portrait-img-view {
  aspect-ratio: 2 / 3;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #282828;
  /* background: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 16rem;
  border-radius: 8px;
  border: 1px solid #282828;
  // pointer-events: none;
  z-index: 9;
  cursor: pointer;

}

.swiper-slide {
  .skeleton {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
    overflow: hidden;
    }
}

.slider-category-title {
  font-size: 1.3rem;
  margin: 0.8rem 0.1rem;
  color: $clr-saina-light-dark-txt;
  min-height: 24px;
  border-radius: 8px;
  width: 11.9vw;
  background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
  overflow: hidden;
}

.swiper-button-next-overlay {
  height: 15rem;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.85) 30%, rgba(0, 0, 0, 0.0) 90%);
  position: absolute;
  width: 3rem;
  top: 0px;
  display: block;
  z-index: 9999;
  // pointer-events: none;
  margin-top: auto !important;
}

::after {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.swiper-button-next-overlay[aria-disabled="true"] .swiper-button-prev-overlay[aria-disabled="true"] {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.85) 90%);
}

.swiper-button-prev-overlay {
  height: 16rem;
  position: absolute;
  width: 3rem;
  top: 0px;
  right: 0;
  display: block;
  z-index: 9999;
  margin-top: auto !important;
}


.onhover-poster.visible {
  opacity: 1;
  visibility: visible;
}

.onhover-poster.hidden {
  opacity: 0;
  visibility: hidden;
}

.onhover-poster {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in, visibility 0.3s ease-in, width 0.3s ease-in;
  position: absolute;
  z-index: 999;
  left: 0.5rem;
  cursor: pointer;
  .hover-card {
    z-index: 999;

    width: 20rem;
    height: 25rem;
    background: #101113;
    transform-origin: center center;
    position: relative;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 3px #00000073;

    figure {
      width: 100%;
      height: 50%;

      .image__item_video {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem 0.5rem 0 0;
        height: 12rem;
        object-fit: cover;

      }

      .image__item {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem 0.5rem 0;
      }

      .related-meta-data {
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .related-action-btns {
          display: flex;
          gap: 1rem;
        }

        .related-meta-data-details {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .fig-title {
            font-weight: 500;
            font-size: 1rem;
          }

          .fig-details {
            display: flex;
            gap: 0.8rem;
            flex-direction: column;

            .main-info {
              display: flex;
              gap: 1rem;
              font-size: 0.85rem;
              align-items: center;

              .publish-year,
              .duration {
                color: #aaaaaa;
              }

              .pgrating {
                border: #3b3a3a 0.8px solid;
                padding: 0.1rem 0.4rem;
                border-radius: 0.2rem;
                color: #ffffff;
              }
            }

            .main-genre {
              display: flex;
              gap: 1rem;
              font-size: 0.85rem;
            }

            .main-genre .sub-genre2 p::before,
            .main-genre .sub-genre3 p::before {
              content: "• ";
              position: relative;
              right: 0.4rem;
            }
          }

          .fig-description {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            color: rgba(170, 170, 170, 1);
            font-weight: 400;
            font-size: 0.8rem;
            height: 3rem;
          }
        }
      }
    }
  }

}

.mute-unmute-btn-slider {
  position: absolute;
  right: 1rem;
  bottom: 2rem;
  z-index: 999999;
}

.home-class-main {
 // margin-left: 1rem;
  position: relative;
}


.next-btn-onhover {
  background: #000;
  width: 4rem;
  height: 24rem;
  position: absolute;
  opacity: 0.3;
  border-radius: 10px;

}

.prev-btn-onhover {
  background: #000;
  width: 4rem;
  height: 24rem;
  position: absolute;
  opacity: 0.3;
  border-radius: 10px;
}

.slider-header {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0px 0.7rem;

  .seeall {
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.75px;
    padding: 0 5px;
    transition: 0.1s;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0rem;
    margin-bottom: 3rem;

    &:hover {
      transform: scale(1.05);
      color: $clr-light-gd3;
    }
  }

  .seeall-arrow {
    position: relative;
    top: 0.22rem;
    width: 1rem;
    height: 1.6rem;
  }

  .slider-head {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .slider-head-btns {
      margin-top: 3px;

      .slider-controls {
        button {
          border: none;
          background: transparent;


          cursor: pointer;

          img {
            height: 1.6rem;
            width: auto;
          }

          &:disabled {
            opacity: 0.3;
            display: flex;
          }

          &:focus {
            outline: none;
          }
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  transform: scale(0.7);
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  transform: scale(0.7);

}

.slider-content {
  .content-slider {
    &:focus {
      outline: none;
    }
  }
}

@media screen and (max-width: 786px) {
  .next-btn-onhover, .prev-btn-onhover {
    width: 2.5rem;
    height: 5rem;
  }
}


</style>